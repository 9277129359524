<template>
  <div class="bankInfo">
    <mt-header :title="$t('bankInfo')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="item">
        <img class="logo" src="../../../assets/logo.png">
        <div class="name">HHxxxx</div>
        <div class="number">****6666</div>
      </div>
      <div class="info">
        <mt-cell :title="$t('deductionSequence')" is-link to="/mine/deductionSequence">
          <img slot="icon" src="../../../assets/deductionSequence.png" width="18" style="margin: 0px 10px">
        </mt-cell>
        <mt-cell :title="$t('checkTheBill')" is-link to="/mine/orderHistory">
          <img slot="icon" src="../../../assets/checkTheBill.png" width="15" style="margin: 0px 11px 0px 12px">
        </mt-cell>
        <mt-cell :title="$t('unbind')" is-link @click.native="onUnind">
          <img slot="icon" src="../../../assets/unbind.png" width="17" style="margin: 0px 11px 0px 10px">
        </mt-cell>
      </div>
    </div>
    <mt-popup
      class="unbind"
      v-model="popupVisible"
    >
      <div class="box">
        <div class="title">{{ $t('warmTips') }}</div>
        <div class="content">
          {{ $t('unbindTips') }}
        </div>
        <div class="bottom">
          <mt-button type="primary" size="large" @click="popupVisible = false">{{
            $t("cancel")
          }}</mt-button>
          <mt-button type="primary" size="large">{{
            $t("confirm")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
export default {
  name: 'BankInfo',
  components: {
    callPhone
  },
  data () {
    return {
      popupVisible: false,
    }
  },
  methods: {
    // 解绑
    onUnind() {
      this.popupVisible = true
      console.log('onUnind')
    }
  }
}
</script>

<style lang="scss" scoped>
.bankInfo {
  > .content {
    padding: 0px 40px;
    .item {
      margin-top: 25px;
      height: 80px;
      border-radius: 10px;
      background: linear-gradient(#5188EF, #6FA0F0);
      padding: 10px 15px;
      display: flex;
      font-size: 15px;
      color: #FFFFFF;
      font-weight: bold;
      line-height: 24px;
      .logo {
        height: 33px;
        margin-right: 10px;
      }
      .name {
        flex: 1;
      }
    }
    .info {
      margin: 18px 0px;
      /deep/ .mint-cell-wrapper {
        padding-left: 0px;
        padding-right: 0px;
        color: #393939;
        font-size: 15px;
      }
    }
  }
  .unbind {
    border-radius: 10px;
    overflow: hidden;
    .box {
      width: 260px;
      .title {
        text-align: center;
        border-bottom: 1px solid #E4E4E4;
        font-size: 13px;
        padding: 8px;
      }
      .content {
        font-size: 12px;
        padding: 20px 10px;
        text-align: center;
      }
      .bottom {
        border-top: 1px solid #E4E4E4;
        display: flex;
        .mint-button {
          height: 35px;
          background-color: white;
          color: #9B9B9B;
          font-size: 13px;
          border-radius: 0px;
          &:last-child {
            color: #F15234;
            border-left: 1px solid #E4E4E4;
          }
        }
      }
    }
  }
}
</style>